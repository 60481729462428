import { Component } from '../../../libs/components';

export class VideoComponent extends Component {
  onInit() {
    const cover:HTMLButtonElement = document.querySelector('[data-video-cover]');
    const video:HTMLVideoElement = document.querySelector("[data-video]")

    cover.addEventListener('click', (e:MouseEvent) => {
      e.preventDefault()
      cover.classList.add('video__poster--hidden');
      video.play()
    })

    video.addEventListener("ended", () => {
      if (video.paused) {
        cover.classList.remove('video__poster--hidden');
      }
    })
  }
}

VideoComponent.declare("video");
