import { Component } from '../../../libs/components';

export class ImpressumComponent extends Component {
  onInit() {
    let isOpen = false;
    const toggleButton:HTMLButtonElement = document.querySelector('[data-impressum-toggle]');
    const impressum:HTMLVideoElement = document.querySelector("[data-impressum]");
    const footer:HTMLElement = document.querySelector("[data-footer]");

    toggleButton.addEventListener("click", (e:MouseEvent) => {
      e.preventDefault()
      toggle()
    })

    const toggle = () => {
      if (isOpen) {
        close()
      } else {
        open()
      }
    }
  
    const open = () => {
      document.body.style.paddingBottom = `${footer.offsetHeight + 24}px`;
      footer.classList.remove("footer--collapsed");
      const naturalHeight = impressum.scrollHeight;
      footer.style.position = "fixed"
      const openAnimation = impressum.animate([
        {
          height: 0,
        }, {
          height: `${naturalHeight}px`,
        }
      ], {
        duration: 250,
        easing: "ease-in-out",
        fill: "forwards",
      })
      

      openAnimation.addEventListener("finish", () => {
        impressum.style.height = ""
      })
      isOpen = true;
    }

    const close = () => {
      const naturalHeight = impressum.scrollHeight;
      const closeAnimation = impressum.animate([
        {
          height: `${naturalHeight}px`,
        }, {
          height: "0",
        }
      ], {
        duration: 250,
        easing: "ease-in-out",
        fill: "forwards",
      })
      closeAnimation.addEventListener("finish", () => {
        footer.style.position = "static";
        document.body.style.paddingBottom = "0";
        footer.classList.add("footer--collapsed");
        footer.scrollTop = 0;
      })
      isOpen = false;
    }
  }
}

ImpressumComponent.declare("[data-impressum]");
