document.addEventListener("DOMContentLoaded", () => {
  
  document.body.addEventListener("submit", (e) => {
    e.preventDefault()
    const target:HTMLElement = e.target as HTMLElement
    if (target.hasAttribute("data-contact-form")) {
      submitContactForm(target as HTMLFormElement)
    }
  })

  const submitContactForm = (form:HTMLFormElement) => {
    fetch(form.getAttribute('action'), {
      method: form.getAttribute('method'),
      body: new FormData(form),
    }).then(res=>res.text())
      .then((data) => {
        const dataDom = new DOMParser().parseFromString(data, "text/html")
        const results = dataDom.querySelector("[data-contact-content]")
        const slot:HTMLElement = document.querySelector("[data-overlay-slot]")
        slot.innerHTML = ''
        slot.append(results)
      });
  }
})