document.addEventListener("DOMContentLoaded", () => {
  const searchForm:HTMLFormElement = document.querySelector("[data-search-form]")
  if (searchForm) {
    init(searchForm)
  }
});

declare var _paq: any; // eslint-disable-line

const init = (searchForm) => {
  const resultsContainer:HTMLElement = document.querySelector("[data-search-results]")
  const inputs:NodeListOf<HTMLInputElement> = searchForm.querySelectorAll("input[type='checkbox']")

  inputs.forEach((input) => {
    input.addEventListener("change", () => {
      submit()
    })
  })

  searchForm.addEventListener("submit", () => {
    submit()
  });

  const submit = () => {
    const data = new FormData(searchForm as HTMLFormElement);
    const queryString:string = new URLSearchParams(data).toString();
    document.body.classList.add('loading')

    fetch(`${searchForm.getAttribute('action')}?${queryString}`, {
      method: searchForm.getAttribute('method'),
    }).then(res=>res.text())
      .then((data) => {
        _paq.push(['setCustomUrl', `${location.protocol}//${location.host}${searchForm.getAttribute('action')}?${queryString}`]);
        _paq.push(['trackPageView']);

        const dataDom = new DOMParser().parseFromString(data, "text/html")
        resultsContainer.innerHTML = ''
        const results:HTMLElement = dataDom.querySelector("[data-results]")
        document.body.classList.remove('loading')
        _paq.push(['trackSiteSearch',
          // Search keyword searched for
          queryString,
          // Search category selected in your search engine. If you do not need this, set to false
          "Studiensuche",
          // Number of results on the Search results page. Zero indicates a 'No Result Search Keyword'. Set to false if you don't know
          results.querySelectorAll(".study").length,
      ]);
        if (results) {
          console.log('test');
          results.style.opacity = "0"
          resultsContainer.append(results)
          results.animate(
            [
              {opacity: "0"},
              {opacity: "1"},
            ], {
              duration: 250,
              iterations: 1,
              fill: 'forwards',
            }
          )

          const accordions = document.querySelectorAll("[data-text-accordion]");
          accordions.forEach((accordion) => {
            const target = accordion.querySelector("[data-text-accordion-target]") as HTMLElement;

            if (target.scrollHeight <= target.clientHeight) {
              target.style.height = "auto";
              accordion.querySelector("[data-text-accordion-button]").remove()
            }
          })
        }
      });
  }

  // searchForm.dispatchEvent(new Event('submit'));
}
