document.addEventListener("DOMContentLoaded", () => {
  const resultsPanel = document.querySelector("[data-search-results-wrapper]");

  document.body.addEventListener("click", (e:MouseEvent) => {
    const target:HTMLElement = e.target as HTMLElement
    
    if (target.closest("[data-results-toggle]")) {
      resultsPanel.classList.toggle("results--expanded");
      if (!resultsPanel.classList.contains("results--expanded")) {
        resultsPanel.scrollTop = 0;
      }
    }
  })
})