export type OnElementsAddedCallback = (addedElements: HTMLElement[]) => void;

export class ElementMutationObserver {
  private observerRoot: HTMLElement
  constructor(observerRoot: HTMLElement) {
    this.observerRoot = observerRoot
  }

  onElementsAdded(callback: OnElementsAddedCallback) {
    const mutationObserver = new MutationObserver((mutations: MutationRecord[]) => {
      const addedElements = this.getAddedElements(mutations);

      if (addedElements.length > 0) {
        callback(addedElements);
      }
    });

    mutationObserver.observe(this.observerRoot, {
      childList: true,
      subtree: true,
    });
  }

  private getAddedElements(mutations: MutationRecord[]): HTMLElement[] {
    const nodes = mutations
      .filter((mutation) => mutation.addedNodes?.length > 0)
      .map((mutation) => {
        const elements = [];

        for (let i = 0; i < mutation.addedNodes.length; i++) {
          const node = mutation.addedNodes[i];

          if (node instanceof HTMLElement) {
            elements.push(node);
          }
        }

        return elements;
      })
      .flat(1);

    // flatten array of nodes
    return ([] as HTMLElement[]).concat(...nodes);
  }
}
