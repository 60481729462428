document.addEventListener("DOMContentLoaded", () => {
  const togglers = document.querySelectorAll("[data-toggle]")

  togglers.forEach((toggler) => {
    toggler.addEventListener("click", (e:MouseEvent) => {
      e.preventDefault();
      const targetString:string = toggler.getAttribute("data-toggle")
      const target:HTMLElement = document.querySelector(`[data-field-block=${targetString}]`)
      target.classList.toggle("hidden")
    })
  })
})