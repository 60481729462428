document.addEventListener("DOMContentLoaded", () => {
  const headers = document.querySelectorAll("[data-accordion-header]");

  headers.forEach((header) => {
    header.addEventListener('click', (e:MouseEvent) => {
      toggleAccordion(e)
    })
  })

  const toggleAccordion = (e:MouseEvent):void => {
    const clickedButton = e.target
    const content:HTMLElement = (clickedButton as HTMLElement).closest("[data-accordion-item]")
      .querySelector("[data-accordion-content]");
    if (content.hasAttribute("hidden")) {
      expand(content)
    } else {
      collapse(content)
    }
  }

  const expand = (el:HTMLElement):void => {
    el.removeAttribute("hidden")
    const child:HTMLElement = el.querySelector("[data-accordion-content-inner]")
    const naturalHeight = child.offsetHeight
    const expandAnimation = el.animate([
      {
        height: `${naturalHeight}px`
      },
    ], {
      duration: 125,
      fill: 'forwards',
    })
    expandAnimation.addEventListener('finish', () => {
      el.removeAttribute("style")
    })
  }

  const collapse = (el:HTMLElement):void => {
    const collapseAnimation = el.animate([
      {
        height: "0"
      },
    ], {
      duration: 125,
      fill: 'forwards',
    })
    collapseAnimation.addEventListener("finish", () => {
      el.removeAttribute("style")
      el.setAttribute("hidden", "")
    })
  }
});