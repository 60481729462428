document.addEventListener("DOMContentLoaded", () => {
  
  document.body.addEventListener("click", (e:MouseEvent) => {
    const target:HTMLElement = e.target as HTMLElement
    if (target.hasAttribute("data-text-accordion-button")) {
      const textToOpen:HTMLElement = target.closest("[data-text-accordion]").querySelector("[data-text-accordion-target]");
      
      const expand = [
        {"height": `${textToOpen.scrollHeight}px`},
      ]

      textToOpen.animate(
        expand,
        {
          "fill": 'forwards',
          "duration": 250,
          "easing": "ease-in-out",
        }
      )
      target.remove()
    }
  })
})