document.addEventListener("DOMContentLoaded", () => {
  const overlays = document.querySelectorAll("[data-overlay]");

  // Delegate "open overlay" listener to body
  // because we will be adding buttons after an ajax call
  document.body.addEventListener("click", (e:MouseEvent) => {
    const target:HTMLElement = e.target as HTMLElement
    const openOverlayButton = target.closest("[data-open-overlay]")
    if (openOverlayButton) {
      e.stopPropagation()
      e.preventDefault()
      const target = openOverlayButton.getAttribute("data-open-overlay")
      const targetOverlay = document.querySelector(`[data-overlay='${target}']`) as HTMLElement
      const asyncUrl:string = openOverlayButton.getAttribute('data-async')
      closeAllOverlays()
      open(targetOverlay)

      if (asyncUrl) {
        loadAsyncContent(asyncUrl, targetOverlay)
      }
    }
  })

  document.addEventListener('keydown', (e:KeyboardEvent) => {
    if (e.key === "Escape") {
      closeAllOverlays()
    }
  })

  document.addEventListener('mousedown', () => {
    closeAllOverlays()
  })

  overlays.forEach((overlay) => {
    overlay.addEventListener("mousedown", (e:MouseEvent) => {
      e.stopPropagation()
      const target:HTMLElement = e.target as HTMLElement
      const closeOverlayButton = target.closest("[data-close-overlay]")
      if (closeOverlayButton) {
        const target:HTMLElement = e.currentTarget as  HTMLElement
        const overlay = target.closest("[data-overlay]") as HTMLElement
        close(overlay)
      }
    })
  })
  
  const closeAllOverlays = () => {
    overlays.forEach((overlay:HTMLElement) => {
      close(overlay)
    })
  }

  const open = (overlay:HTMLElement):void => {
    overlay.classList.add("overlay--open");
    document.body.classList.add("scroll-locked");
  }

  const close = (overlay:HTMLElement):void => {
    overlay.classList.remove("overlay--open");
    document.body.classList.remove("scroll-locked");
  }

  const loadAsyncContent = (url:string, overlay:HTMLElement): void => {
    overlay.classList.add('loading')

    fetch(url, {
      method: 'GET',
    }).then(res=>res.text())
      .then((data) => {
        overlay.classList.remove('loading')
        const slot:HTMLElement = overlay.querySelector("[data-overlay-slot]")
        slot.innerHTML = ''
        const dataDom = new DOMParser().parseFromString(data, "text/html")
        const results = dataDom.querySelector("[data-contact-content]")
        slot.append(results)
      })
  }
})